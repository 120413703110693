export const locale = {
  lang: 'fr',
  data: {
    "YOUR": "VOTRE",
    "HEALTH": "SANTÉ",
    "DONATE": "DONNER",
    "MUTE": "COUPER LE SON",
    "UNMUTE": "RÉACTIVER LE SON",
    "COMMENTS": "COMMENTAIRES",
    "TOP_10": "TOP 10",
    "SHARE": "PARTAGER",
    "SCORE_HIT": "COUP RÉUSSI",
    "CLICK_TO": "CLIQUEZ POUR",
    "PLAY": "JOUER",
    "EASY": "FACILE",
    "EXPERT": "EXPERT",
    "SCORE": "SCORE",
    "YOUR_RANK": "VOTRE CLASSEMENT",
    "YOU_WIN": "VOUS GAGNEZ",
    "YOUR_NAME": "VOTRE NOM",
    "SUBMIT": "ENVOYER",
    "YOU_LOSE": "VOUS PERDEZ",
    "TRY_AGAIN": "RÉESSAYER",
    "WATCH_AD": "REGARDER UNE PUB",
    "PLAYING": "EN TRAIN DE JOUER",
    "KEEP": "GARDER",
    "TOP_10_BOARDS": "TOP 10 TABLEAUX",
    "BACK": "RETOUR",
    "SHARE_ON_SOCIAL_MEDIA": "PARTAGER SUR LES RÉSEAUX SOCIAUX",
    "END_GAME": "FIN DE JEU",
    "RESUME": "REPRENDRE",
    "LEAVE_COMMENT": "LAISSER UN COMMENTAIRE",
    "YOUR_COMMENT": "VOTRE COMMENTAIRE",
    "COPIED_TO_CLIPBOARD": "Copié dans le presse-papiers !",
    "FIND_US": "TROUVEZ-NOUS",
    "CONTACT_US": "CONTACTEZ-NOUS",
    "NAME_EMAIL": "NOM / EMAIL",
    "PASSWORD": "MOT DE PASSE",
    "LOGIN": "CONNEXION",
    "LOGIN_WITH": "SE CONNECTER AVEC",
    "SIGN_UP": "S'INSCRIRE",
    "SIGN_IN": "SE CONNECTER",
    "YOUR_USERNAME": "VOTRE NOM",
    "YOUR_EMAIL": "VOTRE EMAIL",
    "VICTORY": "VICTOIRE",
    "YOUR_UNLOCKED": "DÉBLOQUÉ",
    "ALL_WEAPONS": "TOUS LES ARMES",
    "SHARE_ON": "PARTAGER SUR",
    "SOCIAL_MEDIA": "RÉSEAUX SOCIAUX",
    "ERROR": "ERREUR, ESSAYEZ ENCORE.",
    "Dedicated_to_the_souls_of_the_martyrs": "Dédié aux âmes des martyrs de Gaza, qui ont donné leur vie pour défendre la liberté et la dignité, nous restons tant que la branche d'olivier perdure.",

    "congratulations": "Félicitations",
    "LEVEL_UP": "NIVEAU SUPÉRIEUR",
    "WEAPON": "ARME",
    "UNLOCKED": "DÉBLOQUÉ",
    "INVITE": "INVITER",
    "SAVE_EXIT": "SAUVEGARDER / QUITTER",

    "SAVE_YOUR_RANK": "SAUVEGARDE TON RANG",
    "Privacy Policy": "Politique de Confidentialité",
    "Contact Us": "Contactez-nous",
    "Terms & Conditions": "Termes et Conditions",
    "shareTXT": "Rejoignez l’aventure de la liberté! Testez vos compétences dans Stick of Freedom. Ne manquez pas le défi et partagez-le!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'Annoncez ici',
    "BUTTON_PWA_TXT": "Ajoutez ce jeu à votre écran d'accueil pour un accès rapide ! Appuyez simplement sur l'icône de partage, puis sélectionnez 'Ajouter à l'écran d'accueil'."
  }
};
