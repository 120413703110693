export const locale = {
  lang: 'zh',
  data: {
    "YOUR": "你的",
    "HEALTH": "健康",
    "DONATE": "捐赠",
    "MUTE": "静音",
    "UNMUTE": "取消静音",
    "COMMENTS": "评论",
    "TOP_10": "前10名",
    "SHARE": "分享",
    "SCORE_HIT": "得分命中",
    "CLICK_TO": "点击以",
    "PLAY": "播放",
    "EASY": "简单",
    "EXPERT": "专家",
    "SCORE": "分数",
    "YOUR_RANK": "你的排名",
    "YOU_WIN": "你赢了",
    "YOUR_NAME": "你的名字",
    "SUBMIT": "提交",
    "YOU_LOSE": "你输了",
    "TRY_AGAIN": "再试一次",
    "WATCH_AD": "观看广告",
    "PLAYING": "正在播放",
    "KEEP": "保持",
    "TOP_10_BOARDS": "前10名排行榜",
    "BACK": "返回",
    "SHARE_ON_SOCIAL_MEDIA": "在社交媒体上分享",
    "END_GAME": "结束游戏",
    "RESUME": "继续",
    "LEAVE_COMMENT": "留下评论",
    "YOUR_COMMENT": "你的评论",
    "COPIED_TO_CLIPBOARD": "已复制到剪贴板！",
    "FIND_US": "找到我们",
    "CONTACT_US": "联系我们",
    "NAME_EMAIL": "姓名 / 电子邮件",
    "PASSWORD": "密码",
    "LOGIN": "登录",
    "LOGIN_WITH": "用...登录",
    "SIGN_UP": "注册",
    "SIGN_IN": "登录",
    "YOUR_USERNAME": "您的姓名",
    "YOUR_EMAIL": "您的电子邮件",
    "VICTORY": "胜利",
    "YOUR_UNLOCKED": "已解锁",
    "ALL_WEAPONS": "所有武器",
    "SHARE_ON": "分享至",
    "SOCIAL_MEDIA": "社交媒体",
    "ERROR": "错误，请再试一次。",
    "Dedicated_to_the_souls_of_the_martyrs": "献给加沙烈士的灵魂，他们为捍卫自由和尊严献出了生命，只要橄榄枝存在，我们就将永存",

    "congratulations": "恭喜",
    "LEVEL_UP": "升级",
    "WEAPON": "武器",
    "UNLOCKED": "已解锁",
    "INVITE": "邀请",
    "SAVE_EXIT": "保存 / 退出",
    "SAVE_YOUR_RANK": "保存你的排名",
    "Privacy Policy": "隐私政策",
    "Contact Us": "联系我们",
    "Terms & Conditions": "条款和条件",
    "shareTXT": "加入自由冒险吧！在《自由之棒》中测试你的技能。不要错过这个挑战，分享它吧！",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": '在这里广告',
    "BUTTON_PWA_TXT": "将此游戏添加到主屏幕，方便快速访问！只需点击分享图标，然后选择“添加到主屏幕”。"
  }
};
