export const environment = {
  production: true,
  baseURL: 'https://tesla-secrets.com/',
  // https://maw.woqod.com/fo_api
  // socialShareBaseURL: 'https://woqodweb.ihorizons.com',
  pwaEnabled: true,
  languages: {
    default: 'en',
    saveKey: 'LANGUAGE'
  },
  // nonce: {
  //   isEnabled: true,
  //   value: '8888abcd'
  // },
  // isMagicCursorEnabled: true,
  // googleMapKey: 'AIzaSyCWng_3Uy1-O_BoaUt_z-FgTViRSTIuZCo',
  // recaptchaSiteKey: '6LcTk6YoAAAAAFcWTrePzT5uCg_QyHiR2pbSO5Pd',
  // // 6LcTk6YoAAAAAFcWTrePzT5uCg_QyHiR2pbSO5Pd
  // // 6LcTk6YoAAAAAHUrgOF-08AjcVl3-ZNhacNfT7o5
  // isScrollEnabled: true
};
