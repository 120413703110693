export const locale = {
  lang: 'he',
  data: {
    "YOUR": "שלך",
    "HEALTH": "בריאות",
    "DONATE": "תרום",
    "MUTE": "השתק",
    "UNMUTE": "בטל השתקה",
    "COMMENTS": "תגובות",
    "TOP_10": "עשרת הגדולים",
    "SHARE": "שתף",
    "SCORE_HIT": "מכה מוצלחת",
    "CLICK_TO": "לחץ ל",
    "PLAY": "שחק",
    "EASY": "קל",
    "EXPERT": "מומחה",
    "SCORE": "ניקוד",
    "YOUR_RANK": "הדירוג שלך",
    "YOU_WIN": "ניצחת",
    "YOUR_NAME": "השם שלך",
    "SUBMIT": "שלח",
    "YOU_LOSE": "הפסדת",
    "TRY_AGAIN": "נסה שוב",
    "WATCH_AD": "צפה במודעה",
    "PLAYING": "משחק",
    "KEEP": "שמור",
    "TOP_10_BOARDS": "עשרת לוחות המובילים",
    "BACK": "חזור",
    "SHARE_ON_SOCIAL_MEDIA": "שתף ברשתות חברתיות",
    "END_GAME": "סיום משחק",
    "RESUME": "המשך",
    "LEAVE_COMMENT": "השאר תגובה",
    "YOUR_COMMENT": "התגובה שלך",
    "COPIED_TO_CLIPBOARD": "הועתק ללוח!",
    "FIND_US": "מצא אותנו",
    "CONTACT_US": "צרו קשר",
    "NAME_EMAIL": "שם / אימייל",
    "PASSWORD": "סיסמה",
    "LOGIN": "התחבר",
    "LOGIN_WITH": "התחבר עם",
    "SIGN_UP": "הרשמה",
    "SIGN_IN": "התחברות",
    "YOUR_USERNAME": "השם שלך",
    "YOUR_EMAIL": "האימייל שלך",
    "VICTORY": "ניצחון",
    "YOUR_UNLOCKED": "המשאב שלך נפתח",
    "ALL_WEAPONS": "כל הנשקים",
    "SHARE_ON": "שתף ב",
    "SOCIAL_MEDIA": "רשתות חברתיות",
    "ERROR": "שגיאה, נסה שוב.",
    "Dedicated_to_the_souls_of_the_martyrs": "הקדשה לנשמות השהידים בעזה, שהקריבו את חייהם להגנה על חירות וכבוד, נישאר כל עוד ענף הזית נשאר.",

    "congratulations": "מזל טוב",
    "LEVEL_UP": "עליית רמה",
    "WEAPON": "נשק",
    "UNLOCKED": "נפתח",
    "INVITE": "הזמנה",
    "SAVE_EXIT": "שמור / יציאה",

    "SAVE_YOUR_RANK": "שמור את הדירוג שלך",
    "Privacy Policy": "מדיניות פרטיות",
    "Contact Us": "צור קשר",
    "Terms & Conditions": "תנאים והגבלות",
    "shareTXT": "הצטרפו להרפתקת החירות! בחן את כישוריך ב-Stick of Freedom. אל תפספסו את האתגר ושתפו אותו!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'פרסם כאן',
    "BUTTON_PWA_TXT": "הוסף את המשחק הזה למסך הבית שלך לגישה מהירה! פשוט הקש על סמל השיתוף, ואז בחר 'הוסף למסך הבית'."
  }
};
