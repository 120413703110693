export const locale = {
  lang: 'ru',
  data: {
    "YOUR": "ВАШ",
    "HEALTH": "ЗДОРОВЬЕ",
    "DONATE": "ПОЖЕРТВОВАТЬ",
    "MUTE": "ОТОБРАЖЕНИЕ",
    "UNMUTE": "ВКЛЮЧИТЬ ЗВУК",
    "COMMENTS": "КОММЕНТАРИИ",
    "TOP_10": "ТОП 10",
    "SHARE": "ПОДЕЛИТЬСЯ",
    "SCORE_HIT": "УДАР ПО СЧЕТУ",
    "CLICK_TO": "КЛИКНИТЕ ДЛЯ",
    "PLAY": "ИГРАТЬ",
    "EASY": "ЛЕГКО",
    "EXPERT": "ЭКСПЕРТ",
    "SCORE": "СЧЕТ",
    "YOUR_RANK": "ВАШ РЕЙТИНГ",
    "YOU_WIN": "ВЫ ПОБЕДИЛИ",
    "YOUR_NAME": "ВАШЕ ИМЯ",
    "SUBMIT": "ОТПРАВИТЬ",
    "YOU_LOSE": "ВЫ ПРОИГРАЛИ",
    "TRY_AGAIN": "ПОПРОБУЙТЕ СНОВА",
    "WATCH_AD": "ПОСМОТРЕТЬ РЕКЛАМУ",
    "PLAYING": "ИГРАЕТ",
    "KEEP": "ДЕРЖАТЬ",
    "TOP_10_BOARDS": "ТОП 10 ДОСКИ",
    "BACK": "НАЗАД",
    "SHARE_ON_SOCIAL_MEDIA": "ПОДЕЛИТЬСЯ В СОЦИАЛЬНЫХ СЕТЯХ",
    "END_GAME": "ЗАВЕРШИТЬ ИГРУ",
    "RESUME": "ПРОДОЛЖИТЬ",
    "LEAVE_COMMENT": "ОСТАВИТЬ КОММЕНТАРИЙ",
    "YOUR_COMMENT": "ВАШ КОММЕНТАРИЙ",
    "COPIED_TO_CLIPBOARD": "Скопировано в буфер обмена!",
    "FIND_US": "НАЙДИТЕ НАС",
    "CONTACT_US": "СВЯЖИТЕСЬ С НАМИ",
    "NAME_EMAIL": "ИМЯ / ЭЛЕКТРОННАЯ ПОЧТА",
    "PASSWORD": "ПАРОЛЬ",
    "LOGIN": "ВОЙТИ",
    "LOGIN_WITH": "ВОЙТИ С ПОМОЩЬЮ",
    "SIGN_UP": "РЕГИСТРАЦИЯ",
    "SIGN_IN": "АВТОРИЗАЦИЯ",
    "YOUR_USERNAME": "ВАШЕ ИМЯ",
    "YOUR_EMAIL": "ВАШ EMAIL",
    "VICTORY": "ПОБЕДА",
    "YOUR_UNLOCKED": "ВАШЕ РАСКРЫТО",
    "ALL_WEAPONS": "ВСЕ ОРУДИЯ",
    "SHARE_ON": "ПОДЕЛИТЬСЯ В",
    "SOCIAL_MEDIA": "СОЦИАЛЬНЫЕ СЕТИ",
    "ERROR": "ОШИБКА, ПОПРОБУЙТЕ СНОВА.",
    "Dedicated_to_the_souls_of_the_martyrs": "Посвящается душам мучеников Газы, которые отдали свои жизни за защиту свободы и достоинства, мы остаемся, пока ветвь оливки существует",

    "congratulations": "Поздравляем",
    "LEVEL_UP": "ПОВЫШЕНИЕ УРОВНЯ",
    "WEAPON": "ОРУЖИЕ",
    "UNLOCKED": "РАЗБЛОКИРОВАНО",
    "INVITE": "ПРИГЛАСИТЬ",
    "SAVE_EXIT": "СОХРАНИТЬ / ВЫЙТИ",
    "SAVE_YOUR_RANK": "СОХРАНИТЕ СВОЙ РЕЙТИНГ",
    "Privacy Policy": "Политика конфиденциальности",
    "Contact Us": "Свяжитесь с нами",
    "Terms & Conditions": "Условия и положения",
    "shareTXT": "Присоединяйтесь к приключению свободы! Проверьте свои навыки в Stick of Freedom. Не упустите шанс и поделитесь!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'Рекламируйте здесь',
    "BUTTON_PWA_TXT": "Добавьте эту игру на главный экран для быстрого доступа! Просто нажмите на значок «Поделиться», затем выберите «Добавить на главный экран»."
  }
};
