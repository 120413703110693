<div #cursorMouse class="cursor"></div>
<router-outlet [class.pointer-events-none]="iosCustomPage"></router-outlet>

<div *ngIf="iosCustomPage"
    class="fixed w-full h-full max-w-[90%] max-h-[300px] bg-white inset-0 m-auto flex flex-col items-center justify-center"
    id="ios-pwa-prompt" (click)="closePWAIOS()" style="background-image: url('assets/images/splash.webp');">

    <div class="w-full h-full absolute flex flex-col items-center justify-center bg-white rounded-[19px]">

        <!-- <g transform="translate(334, -12)" (click)="closeADPopUp()" (touchstart)="closeADPopUp()"
            (mousedown)="prevent($event)" (touchmove)="prevent($event)" (touchend)="prevent($event)">
            <image [attr.href]="imageCloseBtnImage?.src" x="0" y="0" width="38" height="38" />
        </g> -->

        <button *ngIf="imageCloseBtnImage?.src" type="type" (click)="closePWAIOS()"
            class="absolute -top-4 -right-4 w-[38px] h-[38px] border border-solid border-white rounded-full text-white text-[32px]"
            [style.background-image]="'url( ' + (imageCloseBtnImage?.src) + ' )'"
            style="background-size: 38px;"></button>

        <!-- <button id="addToHomeScreenBtn" class="text-black">Add to Home Screen</button> -->
        <p class="text-[#7e1a19] px-5 text-lg">{{'BUTTON_PWA_TXT' | translate}}</p>

        <span class="w-16 h-16 bg-[#7e1a19] rounded-full flex items-center justify-center mt-4">
            <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.0344 14.0062C13.0361 14.5585 12.5898 15.0076 12.0375 15.0093C11.4853 15.0111 11.0361 14.5647 11.0344 14.0125L13.0344 14.0062Z"
                    fill="#ffffff" />
                <path
                    d="M9.71867 6.72364L11.0075 5.42672L11.0344 14.0125L13.0344 14.0062L13.0075 5.42045L14.3044 6.70926C14.6961 7.09856 15.3293 7.09659 15.7186 6.70484C16.1079 6.3131 16.1059 5.67993 15.7142 5.29064L11.9955 1.59518L8.30003 5.31387L9.71867 6.72364Z"
                    fill="#ffffff" />
                <path
                    d="M8.30003 5.31387C7.91073 5.70562 7.9127 6.3388 8.30445 6.7281C8.69619 7.1174 9.32938 7.11539 9.71867 6.72364L8.30003 5.31387Z"
                    fill="#ffffff" />
                <path
                    d="M4 12C4 10.8954 4.89543 10 6 10C6.55228 10 7 9.55229 7 9C7 8.44772 6.55228 8 6 8C3.79086 8 2 9.79086 2 12V18C2 20.2091 3.79086 22 6 22H17C19.7614 22 22 19.7614 22 17V12C22 9.79086 20.2091 8 18 8C17.4477 8 17 8.44772 17 9C17 9.55229 17.4477 10 18 10C19.1046 10 20 10.8954 20 12V17C20 18.6569 18.6569 20 17 20H6C4.89543 20 4 19.1046 4 18V12Z"
                    fill="#ffffff" />
            </svg>
        </span>

    </div>
</div>