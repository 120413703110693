import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from './i18n/translation.service';

import { locale as enLang } from './i18n/vocabs/en';
import { locale as arLang } from './i18n/vocabs/ar';
import { locale as hiLang } from './i18n/vocabs/hi';
import { locale as faLang } from './i18n/vocabs/fa';
import { locale as frLang } from './i18n/vocabs/fr';
import { locale as ptLang } from './i18n/vocabs/pt';
import { locale as zhLang } from './i18n/vocabs/zh';
import { locale as esLang } from './i18n/vocabs/es';
import { locale as ruLang } from './i18n/vocabs/ru';
import { locale as heLang } from './i18n/vocabs/he';

import { PlatformService } from './services/platform.service';
import { ResizeService } from './services/resize.service';
import { LoadAssetsService } from './load-assets.service';
// import 'default-passive-events';

// import { openExternalBrowser } from 'open-external-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  imageCloseBtnImage: any = null;
  isFirstTime: boolean = true;
  isInstallable = false;
  deferredPrompt: any;
  iosCustomPage: boolean = false;

  // Listen for the 'beforeinstallprompt' event to show the install button
  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: any) {
    // Prevent the default mini-infobar or dialog from showing
    event.preventDefault();
    // Store the event for later use
    this.deferredPrompt = event;
    // Show the install button
    if (this.isFirstTime) {
      this.isInstallable = true;
      this.isFirstTime = false;
    }
  }

  // Trigger the installation process when the user clicks the install button
  installPWA() {
    this.isInstallable = false;
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();  // Show the install prompt
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.deferredPrompt = null;  // Reset the prompt
      });
    }
  }

  // cursorMouse: any;
  @ViewChild('cursorMouse') cursorMouse!: ElementRef;
  constructor(
    private translationService: TranslationService,
    public platformService: PlatformService,
    private resizeService: ResizeService,
    private loadAssetsService: LoadAssetsService
  ) {
    this.registerTranslations();
    this.resizeService.initializeResizeEvent();
  }


  ngOnInit() {
    // alert(navigator.userAgent);
    // if (this.isInAppBrowser()) {
    //   alert('inAPP 5');
    //   // openExternalBrowser({ where: 'all', to: 'https://stickfreedom.com/' });

    //   // setTimeout(() => {
    //   //   window.open("https://www.google.com/", "_blank", 'noopener,noreferrer');
    //   // });
    // } else {
    //   alert('normal browser 5');
    // }
    this.translationService.setHtmlDirection(this.translationService.getSelectedLanguage());
    window.addEventListener("mousemove", this.positionCursor);
    // this.iosButton();



    this.loadAssetsService.downloadButton$.subscribe((data: any) => {
      if (data && data?.isEnabled && !this.isPWAAlreadyInstalled()) {
        this.handleDownloadBtn();
        // console.log('sdf');

      }
    });


    this.loadAssetsService.images$.subscribe((data: any) => {
      if (data) {
        this.imageCloseBtnImage = data?.['assets/images/imageCloseBtn.webp'];
      }
    })


  }

  // isEligableToShowDownloadButton() {
  //   return this.isInstallable;
  // }

  handleDownloadBtn() {
    this.isInstallable ? this.installPWA() : (this.isIOS() ? this.iosCustomPage = true : this.iosCustomPage = false);
  }

  isPWAAlreadyInstalled() {
    return (window.navigator as any)?.standalone || false;
  }

  isIOS() {
    const isIOS = /iPhone|iPad|iPod/.test(window.navigator.userAgent);
    if (isIOS && !(window.navigator as any).standalone) return true;
    return false;
  }

  iosButton() {
    const isIOS = /iPhone|iPad|iPod/.test(window.navigator.userAgent);
    // const iosCustomPage: any = document.getElementById('ios-pwa-prompt');
    // const addToHomeScreenBtn: any = document.getElementById('addToHomeScreenBtn');

    // Check if it's an iOS device and if the app is not already added to the home screen
    // if (true) {
    if (isIOS && !(window.navigator as any).standalone) {
      // Display the prompt
      // iosCustomPage.style.display = "block";
      this.iosCustomPage = true;

      // Optional: Show a custom message or button after a certain delay to avoid disturbing the user
      // setTimeout(() => {
      //   // iosCustomPage.style.display = "block";
      //   this.iosCustomPage = true;
      // }, 1000);
    }
  }

  closePWAIOS() {
    // alert('Tap the Share icon at the bottom of the Safari browser and select "Add to Home Screen" to install the app.');
    this.iosCustomPage = false;
  }

  positionCursor = (e: any) => {
    const mouseY = e.clientY;
    const mouseX = e.clientX;

    this.cursorMouse.nativeElement.style.top = `${mouseY}px`;
    this.cursorMouse.nativeElement.style.left = `${mouseX}px`;
  }

  registerTranslations() {
    this.translationService.loadTranslations(
      enLang,
      arLang,
      hiLang,
      faLang,
      frLang,
      ptLang,
      zhLang,
      esLang,
      ruLang,
      heLang
    );
    this.translationService.setLanguage(this.translationService.getSelectedLanguage());
  }

  // isInAppBrowser() {
  //   const userAgent = navigator.userAgent;
  //   // Common in-app browser userAgent patterns
  //   return /FBAN|FBAV/i.test(userAgent);
  //   // return /FBAN|FBAV|Instagram|Twitter|Pinterest|Line|WhatsApp|Snapchat|YouTube/i.test(userAgent);
  // }

}
