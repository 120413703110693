export const locale = {
  lang: 'hi',
  data: {
    "YOUR": "आपका",
    "HEALTH": "स्वास्थ्य",
    "DONATE": "दान करें",
    "MUTE": "म्यूट करें",
    "UNMUTE": "अनम्यूट करें",
    "COMMENTS": "टिप्पणियाँ",
    "TOP_10": "शीर्ष 10",
    "SHARE": "साझा करें",
    "SCORE_HIT": "स्कोर हिट",
    "CLICK_TO": "क्लिक करें",
    "PLAY": "खेलें",
    "EASY": "आसान",
    "EXPERT": "विशेषज्ञ",
    "SCORE": "स्कोर",
    "YOUR_RANK": "आपका रैंक",
    "YOU_WIN": "आप जीत गए",
    "YOUR_NAME": "आपका नाम",
    "SUBMIT": "सबमिट करें",
    "YOU_LOSE": "आप हार गए",
    "TRY_AGAIN": "फिर से प्रयास करें",
    "WATCH_AD": "विज्ञापन देखें",
    "PLAYING": "खेल रहे हैं",
    "KEEP": "रखें",
    "TOP_10_BOARDS": "शीर्ष 10 बोर्ड",
    "BACK": "वापस",
    "SHARE_ON_SOCIAL_MEDIA": "सोशल मीडिया पर साझा करें",
    "END_GAME": "खेल समाप्त करें",
    "RESUME": "जारी रखें",
    "LEAVE_COMMENT": "टिप्पणी छोड़ें",
    "YOUR_COMMENT": "आपकी टिप्पणी",
    "COPIED_TO_CLIPBOARD": "क्लिपबोर्ड में कॉपी किया गया!",
    "FIND_US": "हमें खोजें",
    "CONTACT_US": "हमसे संपर्क करें",
    "NAME_EMAIL": "नाम / ईमेल",
    "PASSWORD": "पासवर्ड",
    "LOGIN": "लॉगिन",
    "LOGIN_WITH": "के साथ लॉगिन करें",
    "SIGN_UP": "साइन अप करें",
    "SIGN_IN": "साइन इन करें",
    "YOUR_USERNAME": "आपका नाम",
    "YOUR_EMAIL": "आपका ईमेल",
    "VICTORY": "जीत",
    "YOUR_UNLOCKED": "आपका अनलॉक किया गया",
    "ALL_WEAPONS": "सभी हथियार",
    "SHARE_ON": "साझा करें पर",
    "SOCIAL_MEDIA": "सोशल मीडिया",
    "ERROR": "त्रुटि, फिर से प्रयास करें।",
    "Dedicated_to_the_souls_of_the_martyrs": "गाज़ा के शहीदों की आत्माओं के नाम, जिन्होंने स्वतंत्रता और गरिमा की रक्षा में अपने जीवन का बलिदान दिया, हम तब तक रहेंगे जब तक जैतून की शाखा रहेगी। ",

    "congratulations": "बधाई हो",
    "LEVEL_UP": "स्तर बढ़ाएं",
    "WEAPON": "हथियार",
    "UNLOCKED": "अनलॉक किया गया",
    "INVITE": "आमंत्रित करें",
    "SAVE_EXIT": "सहेजें / बाहर निकलें",
    "SAVE_YOUR_RANK": "अपना रैंक सुरक्षित करें",
    "Privacy Policy": "गोपनीयता नीति",
    "Contact Us": "हमसे संपर्क करें",
    "Terms & Conditions": "नियम और शर्तें",
    "shareTXT": "स्वतंत्रता साहसिक कार्य में शामिल हों! Stick of Freedom में अपनी क्षमताओं का परीक्षण करें। चुनौती को न खोएं और इसे साझा करें!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'यहां विज्ञापन दें',
    "BUTTON_PWA_TXT": "त्वरित पहुंच के लिए इस खेल को अपनी होम स्क्रीन पर जोड़ें! बस शेयर आइकन पर टैप करें, फिर 'होम स्क्रीन पर जोड़ें' चुनें।"
  }
};
