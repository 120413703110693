import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./layouts/main/home-page/home-page.module').then((m) => m.HomePageModule) },
  { path: 'privacy-policy', loadChildren: () => import('./layouts/main/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule) },
  { path: 'contact-us', loadChildren: () => import('./layouts/main/contact-us/contact-us.module').then((m) => m.ContactUsModule) },
  { path: 'terms-and-conditions', loadChildren: () => import('./layouts/main/terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule) },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
