export const locale = {
  lang: 'ar',
  data: {
    "YOUR": "",
    "HEALTH": "صحتك",
    "DONATE": "تبرع",
    "MUTE": "كتم الصوت",
    "UNMUTE": "إلغاء الكتم",
    "COMMENTS": "التعليقات",
    "TOP_10": "أفضل 10",
    "SHARE": "مشاركة",
    "SCORE_HIT": "إصابة ناجحة",
    "CLICK_TO": "اضغط ل",
    "PLAY": "اللعب",
    "EASY": "سهل",
    "EXPERT": "خبير",
    "SCORE": "النقاط",
    "YOUR_RANK": "ترتيبك",
    "YOU_WIN": "لقد انتصرت",
    "YOUR_NAME": "اسمك",
    "SUBMIT": "إرسال",
    "YOU_LOSE": "لقد خسرت",
    "TRY_AGAIN": "حاول مرة أخرى",
    "WATCH_AD": "مشاهدة إعلان",
    "PLAYING": "من خلال",
    "KEEP": "اكمل اللعب",
    "TOP_10_BOARDS": "أفضل 10 لاعبين",
    "BACK": "رجوع",
    "SHARE_ON_SOCIAL_MEDIA": "مشاركة على وسائل التواصل الاجتماعي",
    "END_GAME": "إنهاء اللعبة",
    "RESUME": "استئناف",
    "LEAVE_COMMENT": "اترك تعليقًا",
    "YOUR_COMMENT": "تعليقك",
    "COPIED_TO_CLIPBOARD": "تم النسخ!",
    "FIND_US": "اعثر علينا",
    "CONTACT_US": "تواصل معنا",
    "NAME_EMAIL": "الاسم / البريد الإلكتروني",
    "PASSWORD": "كلمة المرور",
    "LOGIN": "دخول",
    "LOGIN_WITH": "تسجيل الدخول باستخدام",
    "SIGN_UP": "سجل الآن",
    "SIGN_IN": "تسجيل الدخول",
    "YOUR_USERNAME": "اسم المستخدم",
    "YOUR_EMAIL": "البريد الإلكتروني",
    "VICTORY": "النصر",
    "YOUR_UNLOCKED": "تم فتح",
    "ALL_WEAPONS": "جميع الأسلحة",
    "SHARE_ON": "شارك على",
    "SOCIAL_MEDIA": "وسائل التواصل الاجتماعي",
    "ERROR": "خطأ، حاول مرة أخرى.",
    "congratulations": "تهانينا",
    "LEVEL_UP": "رفع المستوى",
    "WEAPON": "سلاح جديد",
    "UNLOCKED": "تم فتح",
    "INVITE": "دعوة",
    "SAVE_EXIT": "حفظ / خروج",
    "Dedicated_to_the_souls_of_the_martyrs": "مهدى إلى أرواح الشهداء في غزة، الذين قدموا أرواحهم دفاعًا عن الحرية والكرامة، نبقى ما دامت غصن الزيتون صامدًا.",
    "SAVE_YOUR_RANK": "احفظ ترتيبك",
    "Privacy Policy": "سياسة الخصوصية",
    "Contact Us": "اتصل بنا",
    "Terms & Conditions": "الشروط والأحكام",
    "shareTXT": "انضم إلى مغامرة الحرية! اختبر مهاراتك في لعبة عصا الحرية. لا تفوت الفرصة وشارك التحدي!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'أعلن هنا',
    "BUTTON_PWA_TXT": "أضف هذه اللعبة إلى شاشتك الرئيسية للوصول السريع! اضغط على أيقونة المشاركة، ثم اختر 'إضافة إلى الشاشة الرئيسية'."
  }
};
