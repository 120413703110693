export const locale = {
  lang: 'pt',
  data: {
    "YOUR": "SEU",
    "HEALTH": "SAÚDE",
    "DONATE": "DOAR",
    "MUTE": "MUTAR",
    "UNMUTE": "DESMUTAR",
    "COMMENTS": "COMENTÁRIOS",
    "TOP_10": "TOP 10",
    "SHARE": "COMPARTILHAR",
    "SCORE_HIT": "PONTUAÇÃO ALCANÇADA",
    "CLICK_TO": "CLIQUE PARA",
    "PLAY": "JOGAR",
    "EASY": "FÁCIL",
    "EXPERT": "EXPERT",
    "SCORE": "PONTUAÇÃO",
    "YOUR_RANK": "SUA CLASSIFICAÇÃO",
    "YOU_WIN": "VOCÊ GANHOU",
    "YOUR_NAME": "SEU NOME",
    "SUBMIT": "ENVIAR",
    "YOU_LOSE": "VOCÊ PERDEU",
    "TRY_AGAIN": "TENTE NOVAMENTE",
    "WATCH_AD": "ASSISTIR ANÚNCIO",
    "PLAYING": "JOGANDO",
    "KEEP": "MANTER",
    "TOP_10_BOARDS": "TOP 10 TABELAS",
    "BACK": "VOLTAR",
    "SHARE_ON_SOCIAL_MEDIA": "COMPARTILHAR NAS REDES SOCIAIS",
    "END_GAME": "FINALIZAR JOGO",
    "RESUME": "RETOMAR",
    "LEAVE_COMMENT": "DEIXE UM COMENTÁRIO",
    "YOUR_COMMENT": "SEU COMENTÁRIO",
    "COPIED_TO_CLIPBOARD": "Copiado para a área de transferência!",
    "FIND_US": "ENCONTRE-NOS",
    "CONTACT_US": "CONTATE-NOS",
    "NAME_EMAIL": "NOME / EMAIL",
    "PASSWORD": "SENHA",
    "LOGIN": "ENTRAR",
    "LOGIN_WITH": "ENTRAR COM",
    "SIGN_UP": "CADASTRAR-SE",
    "SIGN_IN": "FAZER LOGIN",
    "YOUR_USERNAME": "SEU NOME",
    "YOUR_EMAIL": "SEU EMAIL",
    "VICTORY": "VITÓRIA",
    "YOUR_UNLOCKED": "DESBLOQUEADO",
    "ALL_WEAPONS": "TODAS AS ARMAS",
    "SHARE_ON": "COMPARTILHAR EM",
    "SOCIAL_MEDIA": "REDES SOCIAIS",
    "ERROR": "ERRO, TENTE NOVAMENTE.",
    "Dedicated_to_the_souls_of_the_martyrs": "Dedicado às almas dos mártires de Gaza, que deram suas vidas defendendo a liberdade e a dignidade, permaneceremos enquanto o ramo de oliveira existir",

    "congratulations": "Parabéns",
    "LEVEL_UP": "SUBIR DE NÍVEL",
    "WEAPON": "ARMA",
    "UNLOCKED": "DESTRAVADO",
    "INVITE": "CONVIDAR",
    "SAVE_EXIT": "SALVAR / SAIR",
    "SAVE_YOUR_RANK": "SALVE SEU RANK",
    "Privacy Policy": "Política de Privacidade",
    "Contact Us": "Contate-nos",
    "Terms & Conditions": "Termos e Condições",
    "shareTXT": "Junte-se à aventura da liberdade! Teste suas habilidades em Stick of Freedom. Não perca o desafio e compartilhe!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'Anuncie aqui',
    "BUTTON_PWA_TXT": "Adicione este jogo à sua tela inicial para acesso rápido! Basta tocar no ícone de compartilhar e, em seguida, selecionar 'Adicionar à tela inicial'."
  }
};
