export const locale = {
  lang: 'es',
  data: {
    "YOUR": "TU",
    "HEALTH": "SALUD",
    "DONATE": "DONAR",
    "MUTE": "SILENCIAR",
    "UNMUTE": "ACTIVAR SONIDO",
    "COMMENTS": "COMENTARIOS",
    "TOP_10": "TOP 10",
    "SHARE": "COMPARTIR",
    "SCORE_HIT": "PUNTUACIÓN ALCANZADA",
    "CLICK_TO": "HAZ CLIC PARA",
    "PLAY": "JUGAR",
    "EASY": "FÁCIL",
    "EXPERT": "EXPERTO",
    "SCORE": "PUNTUACIÓN",
    "YOUR_RANK": "TU RANGO",
    "YOU_WIN": "HAS GANADO",
    "YOUR_NAME": "TU NOMBRE",
    "SUBMIT": "ENVIAR",
    "YOU_LOSE": "HAS PERDIDO",
    "TRY_AGAIN": "INTENTAR DE NUEVO",
    "WATCH_AD": "VER ANUNCIO",
    "PLAYING": "JUGANDO",
    "KEEP": "MANTENER",
    "TOP_10_BOARDS": "TOP 10 TABLAS",
    "BACK": "VOLVER",
    "SHARE_ON_SOCIAL_MEDIA": "COMPARTIR EN REDES SOCIALES",
    "END_GAME": "TERMINAR JUEGO",
    "RESUME": "REANUDAR",
    "LEAVE_COMMENT": "DEJA UN COMENTARIO",
    "YOUR_COMMENT": "TU COMENTARIO",
    "COPIED_TO_CLIPBOARD": "¡Copiado al portapapeles!",
    "FIND_US": "ENCUÉNTRANOS",
    "CONTACT_US": "CONTÁCTANOS",
    "NAME_EMAIL": "NOMBRE / EMAIL",
    "PASSWORD": "CONTRASEÑA",
    "LOGIN": "INICIAR SESIÓN",
    "LOGIN_WITH": "INICIAR SESIÓN CON",
    "SIGN_UP": "REGISTRARSE",
    "SIGN_IN": "INICIAR SESIÓN",
    "YOUR_USERNAME": "TU NOMBRE",
    "YOUR_EMAIL": "TU EMAIL",
    "VICTORY": "VICTORIA",
    "YOUR_UNLOCKED": "DESBLOQUEADO",
    "ALL_WEAPONS": "TODAS LAS ARMAS",
    "SHARE_ON": "COMPARTIR EN",
    "SOCIAL_MEDIA": "REDES SOCIALES",
    "ERROR": "ERROR, INTENTA DE NUEVO.",
    "congratulations": "Felicidades",
    "LEVEL_UP": "SUBIR DE NIVEL",
    "WEAPON": "ARMA",
    "UNLOCKED": "DESBLOQUEADO",
    "INVITE": "INVITAR",
    "SAVE_EXIT": "GUARDAR / SALIR",
    "Dedicated_to_the_souls_of_the_martyrs": "Dedicado a las almas de los mártires de Gaza, que dieron sus vidas defendiendo la libertad y la dignidad, permanecemos mientras dure la rama de olivo.",
    "SAVE_YOUR_RANK": "GUARDAR TU RANGO",
    "Privacy Policy": "Política de Privacidad",
    "Contact Us": "Contáctanos",
    "Terms & Conditions": "Términos y Condiciones",
    "shareTXT": "¡Únete a la aventura de la libertad! Pon a prueba tus habilidades en Stick of Freedom. ¡No te pierdas el desafío y compártelo!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'Anúnciate aquí',
    "BUTTON_PWA_TXT": "¡Agrega este juego a tu pantalla de inicio para un acceso rápido! Solo toca el icono de compartir y luego selecciona 'Añadir a pantalla de inicio'."
  }
};
