export const locale = {
  lang: 'fa',
  data: {
    "YOUR": "شما",
    "HEALTH": "سلامت",
    "DONATE": "اهدا",
    "MUTE": "بی‌صدا",
    "UNMUTE": "فعال‌سازی صدا",
    "COMMENTS": "نظرات",
    "TOP_10": "۱۰ برتر",
    "SHARE": "اشتراک‌گذاری",
    "SCORE_HIT": "امتیاز زده شد",
    "CLICK_TO": "کلیک کنید برای",
    "PLAY": "بازی",
    "EASY": "آسان",
    "EXPERT": "حرفه‌ای",
    "SCORE": "امتیاز",
    "YOUR_RANK": "رتبه شما",
    "YOU_WIN": "شما برنده شدید",
    "YOUR_NAME": "نام شما",
    "SUBMIT": "ارسال",
    "YOU_LOSE": "شما باختید",
    "TRY_AGAIN": "دوباره امتحان کنید",
    "WATCH_AD": "دیدن تبلیغ",
    "PLAYING": "در حال بازی",
    "KEEP": "نگه داشتن",
    "TOP_10_BOARDS": "۱۰ جدول برتر",
    "BACK": "بازگشت",
    "SHARE_ON_SOCIAL_MEDIA": "اشتراک‌گذاری در شبکه‌های اجتماعی",
    "END_GAME": "پایان بازی",
    "RESUME": "ادامه",
    "LEAVE_COMMENT": "نظر بدهید",
    "YOUR_COMMENT": "نظر شما",
    "COPIED_TO_CLIPBOARD": "کپی به کلیپ بورد شد!",
    "FIND_US": "ما را پیدا کنید",
    "CONTACT_US": "با ما تماس بگیرید",
    "NAME_EMAIL": "نام / ایمیل",
    "PASSWORD": "رمز عبور",
    "LOGIN": "ورود",
    "LOGIN_WITH": "ورود با",
    "SIGN_UP": "ثبت نام کنید",
    "SIGN_IN": "وارد شوید",
    "YOUR_USERNAME": "نام کاربری شما",
    "YOUR_EMAIL": "ایمیل شما",
    "VICTORY": "پیروزی",
    "YOUR_UNLOCKED": "قفل شما باز شده",
    "ALL_WEAPONS": "تمام سلاح‌ها",
    "SHARE_ON": "اشتراک گذاری در",
    "SOCIAL_MEDIA": "رسانه‌های اجتماعی",
    "ERROR": "خطا، لطفاً دوباره تلاش کنید.",
    "Dedicated_to_the_souls_of_the_martyrs": "اهدا به ارواح شهیدان غزه، که جان خود را در دفاع از آزادی و کرامت فدای کردند، ما خواهیم ماند تا زمانی که شاخه زیتون باقی بماند",
    "congratulations": "تبریک",
    "LEVEL_UP": "ارتقاء سطح",
    "WEAPON": "سلاح",
    "UNLOCKED": "باز شده",
    "INVITE": "دعوت",
    "SAVE_EXIT": "ذخیره / خروج",
    "SAVE_YOUR_RANK": "رتبه خود را ذخیره کنید",
    "Privacy Policy": "سیاست حفظ حریم خصوصی",
    "Contact Us": "تماس با ما",
    "Terms & Conditions": "شرایط و قوانین",
    "shareTXT": "به ماجراجویی آزادی بپیوندید! مهارت‌های خود را در Stick of Freedom امتحان کنید. چالش را از دست ندهید و آن را به اشتراک بگذارید!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'اینجا تبلیغ کنید',
    "BUTTON_PWA_TXT": "این بازی را برای دسترسی سریع به صفحه اصلی خود اضافه کنید! کافیست روی آیکون اشتراک‌گذاری بزنید و سپس 'افزودن به صفحه اصلی' را انتخاب کنید."
  }
};
