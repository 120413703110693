export const locale = {
  lang: 'en',
  data: {
    "YOUR": "YOUR",
    "HEALTH": "HEALTH",
    "DONATE": "DONATE",
    "MUTE": "MUTE",
    "UNMUTE": "UNMUTE",
    "COMMENTS": "COMMENTS",
    "TOP_10": "TOP 10",
    "SHARE": "SHARE",
    "SCORE_HIT": "SCORE HIT",
    "CLICK_TO": "CLICK TO",
    "PLAY": "PLAY",
    "EASY": "EASY",
    "EXPERT": "EXPERT",
    "SCORE": "SCORE",
    "YOUR_RANK": "YOUR RANK",
    "YOU_WIN": "YOU WIN",
    "YOUR_NAME": "YOUR NAME",
    "SUBMIT": "SUBMIT",
    "YOU_LOSE": "YOU LOSE",
    "TRY_AGAIN": "TRY AGAIN",
    "WATCH_AD": "WATCH AD",
    "PLAYING": "PLAYING",
    "KEEP": "KEEP",
    "TOP_10_BOARDS": "TOP 10 BOARDS",
    "BACK": "BACK",
    "SHARE_ON_SOCIAL_MEDIA": "SHARE ON SOCIAL MEDIA",
    "END_GAME": "END GAME",
    "RESUME": "RESUME",
    "LEAVE_COMMENT": "LEAVE COMMENT",
    "YOUR_COMMENT": "YOUR COMMENT",
    "COPIED_TO_CLIPBOARD": "Copied to clipboard!",
    "FIND_US": "FIND US",
    "CONTACT_US": "CONTACT US",
    "NAME_EMAIL": "NAME / EMAIL",
    "PASSWORD": "PASSWORD",
    "LOGIN": "LOGIN",
    "LOGIN_WITH": "LOGIN WITH",
    "SIGN_UP": "SIGN UP",
    "SIGN_IN": "SIGN IN",
    "YOUR_USERNAME": "YOUR NAME",
    "YOUR_EMAIL": "YOUR EMAIL",
    "VICTORY": "VICTORY",
    "YOUR_UNLOCKED": "YOUR UNLOCKED",
    "ALL_WEAPONS": "ALL WEAPONS",
    "SHARE_ON": "SHARE ON",
    "SOCIAL_MEDIA": "SOCIAL MEDIA",
    "ERROR": "ERROR, TRY AGAIN.",

    "congratulations": "Congratulations",
    "LEVEL_UP": "LEVEL UP",
    "WEAPON": "WEAPON",
    "UNLOCKED": "UNLOCKED",
    "INVITE": "INVITE",
    "SAVE_EXIT": "SAVE / EXIT",
    "Dedicated_to_the_souls_of_the_martyrs": "Dedicated to the souls of the martyrs of Gaza, who gave their lives defending freedom and dignity, we remain as long as the olive branch endures.",
    "SAVE_YOUR_RANK": "SAVE YOUR RANK",
    "Privacy Policy": "Privacy Policy",
    "Contact Us": "Contact Us",
    "Terms & Conditions": "Terms & Conditions",
    "shareTXT": "Join the freedom adventure! Test your skills in Stick of Freedom. Don’t miss the challenge and share it!",
    "shareH1": "StickOfFreedom",
    "shareH2": "FreedomChallenge",
    "ADVERTISE_HERE": 'Advertise here',
    "BUTTON_PWA_TXT": "Add this game to your home screen for quick access! Just tap the Share icon, then select 'Add to Home Screen'."
  }
};
